<template>
  <div class="policyRegulations">
    <div class="content-wrapper" ref="content">
      <!-- <scroll-list
        class="card-box"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        :data="data"
        :getData="getData">
        <div class="news-wrapper border-bottom-1px" v-for="item in data" :key="item.idCcPolicyRegulation" @click="toNewsDetail(item)">
          <div class="news-info-wrapper">
            <h4>{{item.newsTitle}}</h4>
            <div class="img-list" v-if="item.policyModel">
              <img v-for="(img, index) in (item.coverUrlArr || [])" v-lazy="img" :key="index" alt="">
            </div>
            <p><span>{{item.publishTime}}</span><span>{{item.newsPromulgator}}</span></p>
          </div>
          <img v-if="!item.policyModel" v-lazy="item.coverUrlArr && item.coverUrlArr[0]" alt="">
        </div>
      </scroll-list> -->
      <ListGroup
        class="card-box"
        ref="List"
        :config="config"
      ></ListGroup>
    </div>
  </div>
</template>

<script>
import API from '@/api/index'
import ListGroup from '@/components/list-group/index.js'
const { getRegulationsList } = API

export default {
  components: {
    ListGroup
    // 'scroll-list': () => import('@/components/ScrollList.vue')
  },
  data () {
    return {
      data: [],
      page: 1,
      pageSize: 4,
      total: -1,
      config: {
        getList: (params) => this.getData(params, ''),
        render: (item) => this.renderItem(item)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.contentScrollTop = this.$refs.content.scrollTop
    next()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$refs.content.scrollTop = vm.contentScrollTop
    })
  },
  methods: {
    renderItem (item) {
      return (
        <div class="news-wrapper border-bottom-1px" key={ item.idCcPolicyRegulation } on-click={ () => this.toNewsDetail(item) }>
          {
            !item.policyModel && <img class="img-one" v-lazy={ item.coverUrlArr[0] } alt="" />
          }
          <div class="news-info-wrapper">
            <h4>{ item.policyTitle }</h4>
            {
              item.policyModel
                ? <div class="img-list">
                  {
                    item.coverUrlArr.map(img => {
                      return <img class="img-more" v-lazy={img} key={ img } alt="" />
                    })
                  }
                </div>
                : null
            }
            <p><span>来源：{item.deptName}</span><span>时间：{ item.publishTime && item.publishTime.replace(/-/g, '/').split(' ')[0] }</span></p>
          </div>
        </div>
      )
    },
    async getData ({ page }) {
      return new Promise((resolve, reject) => {
        getRegulationsList({
          start: this.page,
          limit: this.pageSize,
          policyStatus: 3
        }).then(({ data, success, totalCount, message }) => {
          if (success) {
            resolve({
              list: data.map(item => {
                return {
                  ...item,
                  coverUrlArr: (item.newsCoverUrls || '').split(';')
                }
              }),
              total: data.length
            })
          } else {
            reject(new Error(message))
          }
        })
      })
    },
    toNewsDetail (data) {
      this.$router.push({
        name: 'regulationDetails',
        params: {
          id: data.idCcPolicyRegulation || 0
        }
      })
    }
  }
}
</script>

<style lang="scss">
.policyRegulations{
  .news-wrapper{
    display: flex;
    padding-bottom: 28px;
    margin-bottom: 28px;
    .news-info-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      h4{
        font-size: 28px;
        color: #333;
        font-weight: 500;
        margin-bottom: 24px;
      }
      p{
        font-size: 24px;
        color: #999999;
        span:last-child{
          margin-left: 18px;
        }
      }
      .img-list{
        display: flex;
        margin-bottom: 14px;
        .img-more{
          width: 206px;
          height: 114px;
          &:not(:first-child){
            margin-left: 18px;
          }
        }
      }
    }

    .img-one{
      width: 140px;
      height: 140px;
      margin-right: 16px;
    }
  }
}
</style>
